//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    component: {
      type: String,
      default: "form"
    }
  }
};